
  .eden-stepper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .eden-step {
    width: 50px;
    line-height: 50px;
    border: 1px solid grey;
    border-radius: 50%;
    margin: 50px 0px 50px 0px;
    text-align: center;
    pointer-events: none;
  }
  .eden-step-completed {
    background-color: var(--primary-color);
    color: white;
    pointer-events: all;
    cursor: pointer;
  }
  
  .eden-connection {
    width: 80px;
    height: 2px;
    background-color: lightgray;
  }
  .eden-connection-half-fill {
    background: linear-gradient(to right, var(--primary-color) 50%, lightgray 50%);
  }
  .eden-connection-full-fill {
    background: var(--primary-color);
  }

  @media screen and (max-width: 600px) {
    .eden-connection {
      width: 30px;
      height: 2px;
      background-color: lightgray;
    }
    .eden-connection-half-fill {
      background: linear-gradient(to right, var(--primary-color) 50%, lightgray 50%);
    }
    .eden-connection-full-fill {
      background: var(--primary-color);
    }
  }

@media screen and (min-width: 600px) and (max-width: 800px) {
    .eden-connection {
      width: 50px;
      height: 2px;
      background-color: lightgray;
    }
    .eden-connection-half-fill {
      background: linear-gradient(to right, var(--primary-color) 50%, lightgray 50%);
    }
    .eden-connection-full-fill {
      background: var(--primary-color);
    }
  }